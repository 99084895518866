$("input[main-password-field]").on('input', function() {
    // needed for check number mask when autofilling phone field
    if (document.querySelector("input[name='phone']")) {
        let phone = $("input[name='phone']");
        let regexp = /^\d{3}-\d{3}-\d{4}$/;
        if (regexp.test(phone.val())) phone.removeClass('border-danger');
    }

    let password = $(this).val();
    let registerForm = $(this).parents('form');
    let submitButton = $(this).parents('form').find("button[type='submit']");

    let length_requirements_check = false;
    let character_type_requirements_check = false;
    let character_siza_requirements_check = false;

    if (password.length >= 6) {
        length_requirements_check = true;
    }

    if (/\d/.test(password) && /[a-zA-Zа-яА-Я]/.test(password)) {
        character_type_requirements_check = true;
    }

    if (/[a-zа-я]/.test(password) && /[A-ZА-Я]/.test(password)) {
        character_siza_requirements_check = true;
    }

    changeMarkerStatus(
        registerForm.find("div[name='length_requirements']"),
        length_requirements_check
    );

    changeMarkerStatus(
        registerForm.find("div[name='character_type_requirements']"),
        character_type_requirements_check
    );

    changeMarkerStatus(
        registerForm.find("div[name='character_siza_requirements']"),
        character_siza_requirements_check
    );

    if (length_requirements_check
        && character_type_requirements_check
        && character_siza_requirements_check
        && checkRegisterFormInputs($(this))) {

        submitButton.attr('disabled', false);
    } else {
        submitButton.attr('disabled', true);
    }
});

$('.password-control').on('click', function(event){
    event.stopImmediatePropagation();

    let input = $(this).prev();

    if (input.attr('type') == 'password'){
        $(this).addClass('view');
        input.attr('type', 'text');
    } else {
        $(this).removeClass('view');
        input.attr('type', 'password');
    }
});

function changeMarkerStatus(element, status) {
    if (status) {
        element.find(".fa-times").addClass('d-none');
        element.find(".fa-check-circle").removeClass('d-none');

        element.addClass('text-success');
        element.removeClass('text-danger');
    } else {
        element.find(".fa-times").removeClass('d-none');
        element.find(".fa-check-circle").addClass('d-none');

        element.removeClass('text-success');
        element.addClass('text-danger');
    }
}

function checkRegisterFormInputs(currentElement) {
    if (currentElement.parents('form').attr('id') != "register_form") return true;
    let parentForm = currentElement.parents('form');

    if (checkRegisterInput("first_name", parentForm)
        && checkRegisterInput("last_name", parentForm)
        && checkRegisterInput("email", parentForm)
        && checkRegisterInput("phone", parentForm)) {

        return true;
    } else {
        return false;
    }
}

function checkRegisterInput(inputName, parentForm=null) {
    let input = $('#' + inputName);
    if (parentForm !== null) {
        input = $(parentForm).find('#' + inputName);
    }
    return (input.val() == "" || input.hasClass('border-danger')) ? false : true;
}
